import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService } from '../../shared/services/notification.service';
import { LeadStatusService } from 'src/app/shared/services/lead-status.service';
import {
  PreLeadStatusesLoad,
  PreLeadStatusesLoadSuccess,
  PreLeadStatusesLoadError,
  POST_LEAD_STATUSES_LOAD,
  PostLeadStatusesLoad,
  PostLeadStatusesLoadSuccess,
  PostLeadStatusesLoadError,
  DECISION_LEAD_STATUSES_LOAD,
  DecisionLeadStatusesLoad,
  DecisionLeadStatusesLoadSuccess,
  DecisionLeadStatusesLoadError,
  PRE_LEAD_STATUSES_LOAD_ERROR,
  PRE_LEAD_STATUSES_LOAD_SUCCESS,
  PRE_LEAD_STATUSES_LOAD,
  DECISION_LEAD_STATUSES_LOAD_SUCCESS,
  DECISION_LEAD_STATUSES_LOAD_ERROR,
  POST_LEAD_STATUSES_LOAD_SUCCESS,
  POST_LEAD_STATUSES_LOAD_ERROR,
} from '../actions/lead-statuses.actions';

@Injectable()
export class LeadStatusEffects {
  public preLeadStatusesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PreLeadStatusesLoad>(PRE_LEAD_STATUSES_LOAD),
      mergeMap(() =>
        this.leadStatusService.getAllPreLeadStatuses().pipe(
          map(statuses => {
            return new PreLeadStatusesLoadSuccess(statuses);
          }),
          catchError(() => of(new PreLeadStatusesLoadError()))
        )
      )
    )
  );

  public preLeadStatusesLoadSuccess$ = createEffect(
    () => this.actions$.pipe(ofType<PreLeadStatusesLoadSuccess>(PRE_LEAD_STATUSES_LOAD_SUCCESS)),
    { dispatch: false }
  );

  public preLeadStatusesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PreLeadStatusesLoadError>(PRE_LEAD_STATUSES_LOAD_ERROR),
        tap(() => this.notificationService.openSnackBar('error', 'Pre Statuses not loaded'))
      ),
    { dispatch: false }
  );

  public postLeadStatusesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PostLeadStatusesLoad>(POST_LEAD_STATUSES_LOAD),
      mergeMap(() =>
        this.leadStatusService.getAllPostLeadStatuses().pipe(
          map(statuses => {
            return new PostLeadStatusesLoadSuccess(statuses);
          }),
          catchError(() => of(new PostLeadStatusesLoadError()))
        )
      )
    )
  );

  public postLeadStatusesLoadSuccess$ = createEffect(
    () => this.actions$.pipe(ofType<PostLeadStatusesLoadSuccess>(POST_LEAD_STATUSES_LOAD_SUCCESS)),
    { dispatch: false }
  );

  public postLeadStatusesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PostLeadStatusesLoadError>(POST_LEAD_STATUSES_LOAD_ERROR),
        tap(() => this.notificationService.openSnackBar('error', 'Post Statuses not loaded'))
      ),
    { dispatch: false }
  );

  public decisionLeadStatusesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DecisionLeadStatusesLoad>(DECISION_LEAD_STATUSES_LOAD),
      mergeMap(() =>
        this.leadStatusService.getAllDecisionLeadStatuses().pipe(
          map(statuses => {
            return new DecisionLeadStatusesLoadSuccess(statuses);
          }),
          catchError(() => of(new DecisionLeadStatusesLoadError()))
        )
      )
    )
  );

  public decisionLeadStatusesLoadSuccess$ = createEffect(
    () => this.actions$.pipe(ofType<DecisionLeadStatusesLoadSuccess>(DECISION_LEAD_STATUSES_LOAD_SUCCESS)),
    { dispatch: false }
  );

  public decisionLeadStatusesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DecisionLeadStatusesLoadError>(DECISION_LEAD_STATUSES_LOAD_ERROR),
        tap(() => this.notificationService.openSnackBar('error', 'Decision Statuses not loaded'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private leadStatusService: LeadStatusService
  ) {}
}
