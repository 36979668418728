import { Mentee } from 'src/app/shared/types/mentee.types';
import * as MenteesActions from '../actions/mentees.actions';

const initialState: Mentee[] = null;

export function reducer(state = initialState, action: MenteesActions.Actions): Mentee[] {
  switch (action.type) {
    case MenteesActions.MENTEES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
