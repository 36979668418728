import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StatusLead } from '../types/status-lead.types';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LeadStatusService {
  constructor(private http: HttpClient) {}

  /**
   * Returns lead-specific pre-consultation statuses
   * @returns statuses
   */
  public getAllPreLeadStatuses(): Observable<StatusLead[]> {
    return this.http.get<StatusLead[]>(environment.apiUrl + '/pre-lead-statuses/');
  }

  /**
   * Returns lead-specific post-consultation statuses
   * @returns statuses
   */
  public getAllPostLeadStatuses(): Observable<StatusLead[]> {
    return this.http.get<StatusLead[]>(environment.apiUrl + '/post-lead-statuses/');
  }

  /**
   * Returns lead-specific decision statuses
   * @returns statuses
   */
  public getAllDecisionLeadStatuses(): Observable<StatusLead[]> {
    return this.http.get<StatusLead[]>(environment.apiUrl + '/decision-lead-statuses/');
  }
}
