import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Programme } from '../types/programme.types';

@Injectable({
  providedIn: 'root',
})
export class ProgrammeService {
  constructor(private http: HttpClient) {}

  public getAllProgrammes() {
    return this.http.get<Programme[]>(environment.apiUrl + '/programme/all/');
  }
}
