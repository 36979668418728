import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MRStatus } from '../types/mentee-relations.types';
@Injectable({
  providedIn: 'root',
})
export class MrStatusService {
  constructor(private http: HttpClient) {}

  /**
   * Returns mentee relation statuses
   * @returns mr statuses
   */
  public getAllMrStatuses() {
    return this.http.get<MRStatus[]>(environment.apiUrl + '/mr_statuses/');
  }
}
