import { Coach } from 'src/app/shared/types/coach.types';
import * as CoachActions from '../actions/coach.actions';

const initialState: Coach = null;

export function reducer(state = initialState, action: CoachActions.Actions): Coach {
  switch (action.type) {
    case CoachActions.COACH_SET_INITIAL:
      return initialState;
    case CoachActions.COACH_LOAD_SUCCESS:
      return action.payload;
    case CoachActions.UPDATE_COACH_SUCCESS:
      return action.coach;
    default:
      return state;
  }
}
