import * as StatusesActions from '../actions/statuses.actions';
import { Status } from 'src/app/shared/types/status.types';

const initialState: Status[] = null;

export function reducer(state = initialState, action: StatusesActions.Actions): Status[] {
  switch (action.type) {
    case StatusesActions.STATUSES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
