import * as UserActions from '../actions/user.actions';
import { User } from 'src/app/shared/types/user.types';

const initialState: User[] = null;

export function reducer(state = initialState, action: UserActions.Actions): User[] {
  switch (action.type) {
    case UserActions.USERS_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
