import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';

import { NotificationService } from '../../shared/services/notification.service';

import { of } from 'rxjs';
import {
  CoachLoad,
  CoachLoadError,
  CoachLoadSuccess,
  COACH_LOAD,
  COACH_LOAD_ERROR,
  COACH_LOAD_SUCCESS,
  UpdateCoach,
  UpdateCoachError,
  UpdateCoachSuccess,
  UPDATE_COACH,
  UPDATE_COACH_ERROR,
  UPDATE_COACH_SUCCESS,
} from '../actions/coach.actions';
import { Coach } from 'src/app/shared/types/coach.types';
import { CoachService } from 'src/app/shared/services/coach.service';
import { EmailLogLoad } from '../actions/email-log.actions';
import { ConversationLoad } from '../actions/conversation.actions';
import { AnswersLoad } from '../actions/answer.actions';

@Injectable()
export class CoachEffects {
  public coachLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CoachLoad>(COACH_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.coachService.getCoach(payload.id, 'coach').pipe(
          map(data => {
            const coach: Coach = data;
            return new CoachLoadSuccess(coach);
          }),
          catchError(() => of(new CoachLoadError()))
        )
      )
    )
  );

  public coachLoadSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CoachLoadSuccess>(COACH_LOAD_SUCCESS),
      tap(() => (this.notificationService.loading = false)),
      mergeMap(payload => [
        new EmailLogLoad(payload.payload.submission.id),
        new ConversationLoad(payload.payload.submission.id),
        new AnswersLoad(payload.payload.submission.id),
      ])
    )
  );

  public coachLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CoachLoadError>(COACH_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Coach find failed'))
      ),
    { dispatch: false }
  );

  // Update coach

  public updateCoach$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCoach>(UPDATE_COACH),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.coachService.updateCoach(payload.submissionId, payload.coach).pipe(
          map(data => {
            const coach: Coach = data;
            return new UpdateCoachSuccess(coach);
          }),
          catchError(() => of(new UpdateCoachError(payload.submissionId)))
        )
      )
    )
  );

  public updateCoachSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateCoachSuccess>(UPDATE_COACH_SUCCESS),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('success', 'Coach updated')),
        concatMap(val => [new EmailLogLoad(val.coach.submission.id)])
      ),
    { dispatch: true }
  );

  public updateCoachError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateCoachError>(UPDATE_COACH_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Coach update failed'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private coachService: CoachService) {}
}
