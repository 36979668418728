import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  TeamSizesLoad,
  TeamSizesLoadError,
  TeamSizesLoadSuccess,
  TEAMSIZES_LOAD,
  TEAMSIZES_LOAD_ERROR,
  TEAMSIZES_LOAD_SUCCESS,
} from '../actions/team-sizes.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { TeamSizeService } from 'src/app/shared/services/team-size.service';

@Injectable()
export class TeamSizeEffects {
  public teamSizesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TeamSizesLoad>(TEAMSIZES_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.teamSizeService.getTeamSizes().pipe(
          map(teamSizes => {
            return new TeamSizesLoadSuccess(teamSizes);
          }),
          catchError(() => of(new TeamSizesLoadError()))
        )
      )
    )
  );

  public teamSizesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<TeamSizesLoadSuccess>(TEAMSIZES_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public teamSizesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<TeamSizesLoadError>(TEAMSIZES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Team Sizes not loaded'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private teamSizeService: TeamSizeService) {}
}
