import { Coach } from 'src/app/shared/types/coach.types';
import * as CoachesActions from '../actions/coaches.actions';

const initialState: Coach[] = null;

export function reducer(state = initialState, action: CoachesActions.Actions): Coach[] {
  switch (action.type) {
    case CoachesActions.COACHES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
