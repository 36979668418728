import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import {
  PromoCodesLoad,
  PromoCodesLoadError,
  PromoCodesLoadSuccess,
  PROMOCODES_LOAD,
  PROMOCODES_LOAD_ERROR,
  PROMOCODES_LOAD_SUCCESS,
} from '../actions/promo-codes.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { PromoCodeService } from 'src/app/shared/services/promo-code.service';

@Injectable()
export class PromoCodeEffects {
  public promocodesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PromoCodesLoad>(PROMOCODES_LOAD),
      tap(() => (this.notificationService.loading = true)),
      withLatestFrom(this.store.select(state => state.promoCodes)),
      mergeMap(([action, storePromoCodes]) => {
        if (storePromoCodes === null) {
          return this.promocodeService.getAllPromoCodes().pipe(
            map(promocodes => new PromoCodesLoadSuccess(promocodes)),
            catchError(() => of(new PromoCodesLoadError()))
          );
        } else {
          return EMPTY;
        }
      })
    )
  );

  public promocodesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PromoCodesLoadSuccess>(PROMOCODES_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public promocodesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PromoCodesLoadError>(PROMOCODES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Promo Codes not loaded'))
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private notificationService: NotificationService,
    private promocodeService: PromoCodeService
  ) {}
}
