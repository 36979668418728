import { Lead } from 'src/app/shared/types/lead.types';
import * as LeadActions from '../actions/lead.actions';

type ErrorType = string;
const initialState: Lead | ErrorType = null;

export function reducer(state = initialState, action: LeadActions.Actions): Lead | ErrorType {
  switch (action.type) {
    case LeadActions.LEAD_SET_INITIAL:
      return initialState;
    case LeadActions.LEAD_LOAD_SUCCESS:
      return action.payload;
    case LeadActions.UPDATE_LEAD_SUCCESS:
      return action.lead;
    default:
      return state;
  }
}
