import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { EmailLogService } from '../../shared/services/email-log.service';
import { NotificationService } from '../../shared/services/notification.service';
import { EmailLog } from '../../shared/types/email-log.types';
import {
  EmailLogLoadError,
  EmailLogLoadSuccess,
  EmailLogSend,
  EmailLogSendError,
  EmailLogSendSuccess,
  EMAILLOG_LOAD_ERROR,
  EMAILLOG_LOAD_SUCCESS,
  EMAILLOG_SEND,
  EMAILLOG_SEND_ERROR,
  EMAILLOG_SEND_SUCCESS,
  UpdateEmailLog,
  UpdateEmailLogError,
  UpdateEmailLogSuccess,
  UPDATE_EMAILLOG,
  UPDATE_EMAILLOG_ERROR,
  UPDATE_EMAILLOG_SUCCESS,
} from '../actions/email-log.actions';

@Injectable()
export class EmailLogsEffects {
  public emaillogLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EmailLogLoadSuccess>(EMAILLOG_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
        // tap(() => this.notificationService.openSnackBar('success', 'Effect!', 'EmailLog loaded'))
      ),
    { dispatch: false }
  );

  public emaillogLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EmailLogLoadError>(EMAILLOG_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'EmailLog update failed'))
      ),
    { dispatch: false }
  );

  // Update emaillog
  public updateEmailLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEmailLog>(UPDATE_EMAILLOG),
      mergeMap(payload =>
        this.emaillogService.updateEmailLog(payload.emaillog).pipe(
          map(data => {
            const emaillog: EmailLog = data;
            return new UpdateEmailLogSuccess(emaillog);
          }),
          catchError(() => of(new UpdateEmailLogError(payload.emaillog.id)))
        )
      )
    )
  );

  public updateEmailLogSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateEmailLogSuccess>(UPDATE_EMAILLOG_SUCCESS),
        tap(() => this.notificationService.openSnackBar('success', 'EmailLog updated'))
      ),
    { dispatch: false }
  );

  public updateEmailLogError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateEmailLogError>(UPDATE_EMAILLOG_ERROR),
        tap(() => this.notificationService.openSnackBar('error', 'EmailLog update failed'))
      ),
    { dispatch: false }
  );

  public sendEmailLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EmailLogSend>(EMAILLOG_SEND),
      mergeMap(payload =>
        this.emaillogService.sendEmail(payload.emailLog.id).pipe(
          map(data => {
            const emaillog: EmailLog = data.email_log;
            return new EmailLogSendSuccess(emaillog);
          }),
          catchError(() => of(new EmailLogSendError()))
        )
      )
    )
  );

  public sendEmailLogSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EmailLogSendSuccess>(EMAILLOG_SEND_SUCCESS),
        tap(payload => this.notificationService.openSnackBar('success', 'Email has been sent to ' + payload.emailLog.recipient))
      ),
    { dispatch: false }
  );

  public sendEmailLogError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EmailLogSendError>(EMAILLOG_SEND_ERROR),
        tap(() => this.notificationService.openSnackBar('error', 'Email has not been sent'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private emaillogService: EmailLogService) {}
}
