import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../../shared/services/notification.service';
import { MentorsLoadError, MentorsLoadSuccess, MENTORS_LOAD_ERROR, MENTORS_LOAD_SUCCESS } from '../actions/mentors.actions';

@Injectable()
export class MentorsEffects {
  public mentorsLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MentorsLoadSuccess>(MENTORS_LOAD_SUCCESS),
        tap(payload => {
          this.notificationService.loading = false;
        })
      ),
    { dispatch: false }
  );

  public mentorsLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MentorsLoadError>(MENTORS_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Mentors find failed'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService) {}
}
