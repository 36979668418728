import { cloneDeep } from 'lodash';
import { Conversation } from 'src/app/shared/types/conversation.types';
import * as ConversationActions from '../actions/conversation.actions';

const initialState: Conversation[] = null;

export function reducer(state = initialState, action: ConversationActions.Actions): Conversation[] {
  let allConversations: Conversation[];
  let ix: number;
  switch (action.type) {
    case ConversationActions.CONVERSATION_SET_INITIAL:
      return initialState;
    case ConversationActions.CONVERSATION_LOAD_SUCCESS:
      return action.payload;
    case ConversationActions.ADD_COMMENT_TO_CONVERSATION_SUCCESS:
      allConversations = cloneDeep(state);
      ix = allConversations.findIndex(item => item.id === action.payload.id);
      if (ix === -1) {
        allConversations = [...allConversations, action.payload];
      } else {
        allConversations[ix] = action.payload;
      }
      return allConversations;
    case ConversationActions.RESOLVE_CONVERSATION_SUCCESS:
      allConversations = cloneDeep(state);
      if (allConversations) {
        ix = allConversations.findIndex(item => item.id === action.payload.id);
        allConversations.splice(ix, 1);
        return allConversations;
      } else {
        return state;
      }
    case ConversationActions.REMOVE_USER_FROM_CONVERSATION_SUCCESS:
      allConversations = cloneDeep(state);
      ix = allConversations.findIndex(item => item.id === action.payload.id);
      allConversations[ix].notified_users = action.payload.notified_users;
      return allConversations;
    default:
      return state;
  }
}
