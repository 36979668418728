import { AppState } from '.';
import * as FeedbacksActions from '../actions/feedbacks.actions';

const initialState: AppState['feedbacksNeeded'] = null;

export function reducer(state = initialState, action: FeedbacksActions.Actions): AppState['feedbacksNeeded'] {
  switch (action.type) {
    case FeedbacksActions.FEEDBACKS_NEEDED_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
