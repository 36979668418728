<p-progressBar *ngIf="notificationCenter.loading" mode="indeterminate" [style]="{ height: '3px', position: 'fixed' }"></p-progressBar>
<header class="flex align-items-center justify-content-center" *ngIf="(currentUrl$ | async) === '/' + loginUrl; else elseBlock">
  <div style="cursor: pointer" class="logo col-auto align-items-center">
    <img tabindex="-1" routerLink="dashboard" style="width: 150px" src="assets/FP-logo.png" alt="Logo" class="ml-xl-3" />
  </div>
</header>
<ng-template #elseBlock>
  <header class="flex align-items-center pl-3 pr-3">
    <div class="flex justify-content-between align-items-center" style="width: 100%">
      <div style="cursor: pointer" class="logo col-auto align-items-center">
        <img tabindex="-1" routerLink="/dashboard" style="width: 150px" src="assets/FP-logo.png" alt="Logo" />
      </div>
      <div class="flex align-items-center">
        <ng-container *ngIf="(openConversations$ | async)?.length > 0 || (reminders$ | async)?.length > 0; else emptyNotifications">
          <i
            class="pi pi-bell mr-3 text-2xl p-text-secondary cursor-pointer"
            severity="warning"
            (click)="navigateToNotifications()"
            pBadge
            [value]="(openConversations$ | async)?.length + (reminders$ | async)?.length"
            pTooltip="Notifications"
            tooltipPosition="left"
          ></i>
        </ng-container>
        <p-avatar
          #avatar
          [label]="(user$ | async)?.username[0].toUpperCase()"
          styleClass="mr-2"
          shape="circle"
          onselectstart="false"
          class="noselect"
          styleClass="user-avatar p-shadow-1 cursor-auto"
          severity="danger"
          size="large"
          (click)="menu.toggle($event)"
          pRipple
        ></p-avatar>
        <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
      </div>
    </div>
  </header>
</ng-template>

<ng-template #emptyNotifications>
  <i
    class="pi pi-bell mr-3 text-2xl p-text-secondary cursor-pointer"
    (click)="navigateToNotifications()"
    pTooltip="Notifications"
    tooltipPosition="left"
  ></i>
</ng-template>

<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" (onClose)="onReject()" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
        </div>
        <div class="col-6">
          <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-scrollTop></p-scrollTop>
