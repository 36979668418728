import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { FieldService } from 'src/app/shared/services/field.service';
import { NotificationService } from '../../shared/services/notification.service';
import {
  FieldsLoad,
  FieldsLoadError,
  FieldsLoadSuccess,
  FIELDS_LOAD,
  FIELDS_LOAD_ERROR,
  FIELDS_LOAD_SUCCESS,
} from '../actions/fields.actions';

@Injectable()
export class FieldEffects {
  public fieldsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FieldsLoad>(FIELDS_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.fieldService.getAllFields().pipe(
          map(fields => {
            return new FieldsLoadSuccess(fields);
          }),
          catchError(() => of(new FieldsLoadError()))
        )
      )
    )
  );

  public fieldsLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FieldsLoadSuccess>(FIELDS_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public fieldsLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FieldsLoadError>(FIELDS_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Fields not loaded'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private fieldService: FieldService) {}
}
