import * as BadgesActions from '../actions/badges.actions';
import { Badge } from 'src/app/shared/types/badge.types';

const initialState: Badge[] = null;

export function reducer(state = initialState, action: BadgesActions.Actions): Badge[] {
  switch (action.type) {
    case BadgesActions.BADGES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
