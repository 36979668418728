import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  CitiesLoad,
  CitiesLoadError,
  CitiesLoadSuccess,
  CITIES_LOAD,
  CITIES_LOAD_ERROR,
  CITIES_LOAD_SUCCESS,
} from '../actions/cities.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { CityService } from 'src/app/shared/services/city.service';

@Injectable()
export class CityEffects {
  public citiesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CitiesLoad>(CITIES_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.cityService.getCities().pipe(
          map(cities => {
            return new CitiesLoadSuccess(cities);
          }),
          catchError(() => of(new CitiesLoadError()))
        )
      )
    )
  );

  public citiesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CitiesLoadSuccess>(CITIES_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public citiesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CitiesLoadError>(CITIES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Cities not loaded'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private cityService: CityService) {}
}
