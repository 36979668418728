import { createSelector } from '@ngrx/store';
import { AnyModelStr } from 'src/app/shared/types/any-models.types';
import { AppState } from '../reducers';

export const selectModels = (state: AppState) => {
  return { mentee: state.mentee, mentor: state.mentor, coach: state.coach, lead: state.lead };
};

export const selectModelByType = (modelStr: AnyModelStr) =>
  createSelector(selectModels, allModels => {
    if (allModels) {
      if (modelStr == 'mentee') {
        return allModels['mentee'];
      } else if (modelStr == 'mentor') {
        return allModels['mentor'];
      } else if (modelStr == 'coach') {
        return allModels['coach'];
      } else if (modelStr == 'lead') {
        return allModels['lead'];
      } else {
        throw `Unknown model ${modelStr}`;
      }
    }
  });
