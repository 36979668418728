import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';

import { MenteeService } from 'src/app/shared/services/mentee.service';
import { NotificationService } from '../../shared/services/notification.service';

import { of } from 'rxjs';
import { Mentee } from 'src/app/shared/types/mentee.types';
import { AnswersLoad } from '../actions/answer.actions';
import { ConversationLoad } from '../actions/conversation.actions';
import { EmailLogLoad } from '../actions/email-log.actions';
import { MenteeRelationLoad } from '../actions/mentee-relations.actions';
import {
  MenteeLoad,
  MenteeLoadError,
  MenteeLoadSuccess,
  MENTEE_LOAD,
  MENTEE_LOAD_ERROR,
  MENTEE_LOAD_SUCCESS,
  UpdateMentee,
  UpdateMenteeError,
  UpdateMenteeSuccess,
  UPDATE_MENTEE,
  UPDATE_MENTEE_ERROR,
  UPDATE_MENTEE_SUCCESS,
} from '../actions/mentee.actions';

@Injectable()
export class MenteeEffects {
  public menteeLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MenteeLoad>(MENTEE_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.menteeService.getMenteeData(payload.id).pipe(
          map(data => {
            const mentee: Mentee = data;
            return new MenteeLoadSuccess(mentee);
          }),
          catchError(() => of(new MenteeLoadError()))
        )
      )
    )
  );

  public menteeLoadSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MenteeLoadSuccess>(MENTEE_LOAD_SUCCESS),
      tap(() => (this.notificationService.loading = false)),
      mergeMap(payload => [
        new AnswersLoad(payload.payload.submission.id),
        new ConversationLoad(payload.payload.submission.id),
        new EmailLogLoad(payload.payload.submission.id),
      ])
    )
  );

  public menteeLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MenteeLoadError>(MENTEE_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Mentee update failed'))
      ),
    { dispatch: false }
  );

  // Update mentee

  public updateMentee$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateMentee>(UPDATE_MENTEE),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.menteeService.updateMentee(payload.menteeId, payload.mentee).pipe(
          map(data => {
            const mentee: Mentee = data;
            return new UpdateMenteeSuccess(mentee);
          }),
          catchError(error => of(new UpdateMenteeError(error.error)))
        )
      )
    )
  );

  public updateMenteeSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateMenteeSuccess>(UPDATE_MENTEE_SUCCESS),
      tap(() => (this.notificationService.loading = false)),
      tap(() => this.notificationService.openSnackBar('success', 'Mentee updated')),
      concatMap(val => [new MenteeRelationLoad(val.mentee.id, 'mentee'), new EmailLogLoad(val.mentee.submission.id)])
    )
  );

  public updateMenteeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateMenteeError>(UPDATE_MENTEE_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(err => this.notificationService.openSnackBar('error', err.error))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private menteeService: MenteeService) {}
}
