import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  MrStatusesLoad,
  MrStatusesLoadError,
  MrStatusesLoadSuccess,
  MRSTATUSES_LOAD,
  MRSTATUSES_LOAD_ERROR,
  MRSTATUSES_LOAD_SUCCESS,
} from '../actions/mr-statuses.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { MrStatusService } from 'src/app/shared/services/mr-status.service';

@Injectable()
export class MrStatusEffects {
  public mrstatusesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MrStatusesLoad>(MRSTATUSES_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.mrStatusService.getAllMrStatuses().pipe(
          map(mrstatuses => {
            return new MrStatusesLoadSuccess(mrstatuses);
          }),
          catchError(() => of(new MrStatusesLoadError()))
        )
      )
    )
  );

  public mrstatusesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MrStatusesLoadSuccess>(MRSTATUSES_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public mrstatusesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MrStatusesLoadError>(MRSTATUSES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Relation Statuses not loaded'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private mrStatusService: MrStatusService) {}
}
