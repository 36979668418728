import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { SkillService } from 'src/app/shared/services/skill.service';
import { NotificationService } from '../../shared/services/notification.service';
import {
  SkillsLoad,
  SkillsLoadError,
  SkillsLoadSuccess,
  SKILLS_LOAD,
  SKILLS_LOAD_ERROR,
  SKILLS_LOAD_SUCCESS,
} from '../actions/skills.actions';

@Injectable()
export class SkillEffects {
  public skillsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SkillsLoad>(SKILLS_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.skillService.getAllSkills().pipe(
          map(skills => {
            return new SkillsLoadSuccess(skills);
          }),
          catchError(() => of(new SkillsLoadError()))
        )
      )
    )
  );

  public skillsLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SkillsLoadSuccess>(SKILLS_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public skillsLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SkillsLoadError>(SKILLS_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Skills not loaded'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private skillService: SkillService) {}
}
