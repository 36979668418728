import { createReducer, on } from '@ngrx/store';
import * as AnswerTopicsLoad from '../actions/answer-topics.actions';
import { AnswerTopic } from 'src/app/shared/types/answer-topics.types';

export const initialState: AnswerTopic[] = null;

export const reducer = createReducer(
  initialState,
  on(AnswerTopicsLoad.loadData, state => ({ ...state, loading: true })),
  on(AnswerTopicsLoad.loadDataSuccess, (state, { data }) => ({ ...state, data, loading: false })),
  on(AnswerTopicsLoad.loadDataFailure, (state, { error }) => ({ ...state, error, loading: false }))
);
