import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import {
  StatusesLoad,
  StatusesLoadError,
  StatusesLoadSuccess,
  STATUSES_LOAD,
  STATUSES_LOAD_ERROR,
  STATUSES_LOAD_SUCCESS,
} from '../actions/statuses.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { StatusService } from 'src/app/shared/services/status.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';

@Injectable()
export class StatusEffects {
  public statussLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<StatusesLoad>(STATUSES_LOAD),
      tap(() => (this.notificationService.loading = true)),
      withLatestFrom(this.store.select(state => state.statuses)),
      mergeMap(([action, storeStatuses]) => {
        if (storeStatuses === null) {
          return this.statuseService.getAllStatuses().pipe(
            map(statuses => new StatusesLoadSuccess(statuses)),
            catchError(() => of(new StatusesLoadError()))
          );
        } else {
          return EMPTY;
        }
      })
    )
  );

  public statusesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<StatusesLoadSuccess>(STATUSES_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public statusesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<StatusesLoadError>(STATUSES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Statuses not loaded'))
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private notificationService: NotificationService,
    private statuseService: StatusService
  ) {}
}
