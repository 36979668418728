import { Mentor } from 'src/app/shared/types/mentor.types';
import * as MentorsActions from '../actions/mentors.actions';

const initialState: Mentor[] = null;

export function reducer(state = initialState, action: MentorsActions.Actions): Mentor[] {
  switch (action.type) {
    case MentorsActions.MENTORS_LOAD_SUCCESS:
      return action.payload;
    case MentorsActions.UPDATE_MENTORS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
