import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { LOGINURL } from 'src/app/app.routes';
import { AppState } from '../../reducers';

@Component({
  selector: 'fp-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  constructor(private store: Store<AppState>) {}
  visibleSidebar;
  loginUrl = LOGINURL;
  currentUrl$ = this.store.select(state => state.router?.state?.url);
  user$ = this.store.select(state => state.settings.user);
}
