<div [ngSwitch]="currentUrl$ | async">
  <ng-container *ngSwitchCase="'/' + loginUrl" [ngTemplateOutlet]="loginLayout"></ng-container>
  <ng-container *ngSwitchCase="'/' + dlSignupUrl" [ngTemplateOutlet]="loginLayout"></ng-container>
  <ng-container *ngSwitchCase="'/' + passwordResetUrl" [ngTemplateOutlet]="loginLayout"></ng-container>
  <ng-container *ngSwitchCase="'/' + notFounUrl" [ngTemplateOutlet]="noLayout"></ng-container>
  <ng-container *ngSwitchCase="'/' + farewell" [ngTemplateOutlet]="noLayout"></ng-container>
  <ng-container *ngSwitchCase="'/' + maintenanceUrl" [ngTemplateOutlet]="noLayout"></ng-container>
  <ng-container *ngSwitchCase="null || undefined"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="layout"></ng-container>
</div>

<ng-template #loginLayout>
  <fp-layout-login>
    <router-outlet></router-outlet>
  </fp-layout-login>
</ng-template>
<ng-template #layout>
  <fp-layout>
    <router-outlet></router-outlet>
  </fp-layout>
</ng-template>
<ng-template #noLayout>
  <router-outlet></router-outlet>
</ng-template>
