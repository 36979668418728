import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { LOGINURL } from '../../../app.routes';
import { AppState } from '../../reducers';

@Component({
  selector: 'fp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private store: Store<AppState>,
    private messageService: MessageService,
    public notificationCenter: NotificationService,
    private router: Router,
    public utilService: UtilsService
  ) {}

  public menuItems: MenuItem[];

  currentUrl$ = this.store.select(state => state.router?.state?.url);
  user$ = this.store.select(state => state.settings.user);
  openConversations$ = this.store.select(state => state.settings.openConversations);
  reminders$ = this.store.select(state => state.reminders.reminders);
  loginUrl = LOGINURL;

  async ngOnInit() {
    this.menuItems = [
      { label: 'Settings', icon: 'pi pi-cog', command: () => this.navigateToProfile() },
      { label: 'Log out', icon: 'pi pi-sign-out', command: () => this.signOut() },
    ];
  }

  onConfirm() {
    this.messageService.clear('c');
  }

  onReject() {
    this.messageService.clear('c');
  }

  clear() {
    this.messageService.clear();
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate([`/auth/`]);
  }

  navigateToProfile() {
    this.router.navigate(['/profile/settings']);
  }

  navigateToNotifications() {
    this.router.navigate(['/profile/notifications']);
  }
}
