import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Conversation } from '../types/conversation.types';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  constructor(private http: HttpClient) {}

  public getConversationsBySubmission(submission_id: string) {
    return this.http.get<Conversation[]>(environment.apiUrl + '/conversation/submission/' + submission_id + '/');
  }

  public getUserConversations() {
    return this.http.get<Conversation[]>(environment.apiUrl + '/conversation/user/');
  }

  public resolveConversations(conversation_id: string) {
    return this.http.get<Conversation>(environment.apiUrl + '/conversation/' + conversation_id + '/resolve/');
  }

  public createConversation(submissionId: string, field: string) {
    return this.http.post<Conversation>(environment.apiUrl + '/conversation/', {
      submissionId,
      field,
    });
  }

  public removeUserFromConversation(conversation_id: string, user_id: string) {
    return this.http.post<Conversation>(environment.apiUrl + '/conversation/' + conversation_id + '/remove_user/', {
      user_id,
    });
  }
}
