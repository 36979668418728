import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Badge } from '../types/badge.types';
@Injectable({
  providedIn: 'root',
})
export class BadgeService {
  constructor(private http: HttpClient) {}

  /**
   * Returns badge
   * @returns badge
   */
  public getBadges() {
    return this.http.get<Badge[]>(environment.apiUrl + '/company_details/');
  }
}
