import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as AnswerTopicsActions from '../actions/answer-topics.actions';
import { AnswersService } from '../../shared/services/answers.service';

@Injectable()
export class AnswerTopicsEffects {
  answerTopicsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnswerTopicsActions.loadData),
      mergeMap(() =>
        this.answersService.getFocusedTopics().pipe(
          map(data => AnswerTopicsActions.loadDataSuccess({ data })),
          catchError(error => of(AnswerTopicsActions.loadDataFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private answersService: AnswersService) {}
}
