import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FeedbackStatus } from '../types/feedback-status.types';
@Injectable({
  providedIn: 'root',
})
export class FeedbackStatusService {
  constructor(private http: HttpClient) {}

  /**
   * Returns Feedback statuses
   * @returns Feedback statuses
   */
  public getAllFeedbackStatuses() {
    return this.http.get<FeedbackStatus[]>(environment.apiUrl + '/feedback_status/all/');
  }
}
