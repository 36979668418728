import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PromoCode } from '../types/promo-code.types';

@Injectable({
  providedIn: 'root',
})
export class PromoCodeService {
  constructor(private http: HttpClient) {}

  public getAllPromoCodes() {
    return this.http.get<PromoCode[]>(environment.apiUrl + '/promo_codes/');
  }
}
