import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';

import { NotificationService } from '../../shared/services/notification.service';

import { of } from 'rxjs';
import {
  MentorLoad,
  MentorLoadError,
  MentorLoadSuccess,
  MENTOR_LOAD,
  MENTOR_LOAD_ERROR,
  MENTOR_LOAD_SUCCESS,
  UpdateMentor,
  UpdateMentorError,
  UpdateMentorSuccess,
  UPDATE_MENTOR,
  UPDATE_MENTOR_ERROR,
  UPDATE_MENTOR_SUCCESS,
} from '../actions/mentor.actions';
import { Mentor } from 'src/app/shared/types/mentor.types';
import { MentorService } from 'src/app/shared/services/mentor.service';
import { EmailLogLoad } from '../actions/email-log.actions';
import { ConversationLoad } from '../actions/conversation.actions';
import { AnswersLoad } from '../actions/answer.actions';

@Injectable()
export class MentorEffects {
  public mentorLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MentorLoad>(MENTOR_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.mentorService.getMentor(payload.id, 'mentor').pipe(
          map(data => {
            const mentor: Mentor = data;
            return new MentorLoadSuccess(mentor);
          }),
          catchError(() => of(new MentorLoadError()))
        )
      )
    )
  );

  public mentorLoadSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MentorLoadSuccess>(MENTOR_LOAD_SUCCESS),
      tap(() => (this.notificationService.loading = false)),
      mergeMap(payload => [
        new AnswersLoad(payload.payload.submission.id),
        new ConversationLoad(payload.payload.submission.id),
        new EmailLogLoad(payload.payload.submission.id),
      ])
    )
  );

  public mentorLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MentorLoadError>(MENTOR_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Mentor find failed'))
      ),
    { dispatch: false }
  );

  // Update mentor

  public updateMentor$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateMentor>(UPDATE_MENTOR),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.mentorService.updateMentor(payload.mentorId, payload.mentor).pipe(
          map(data => {
            const mentor: Mentor = data;
            return new UpdateMentorSuccess(mentor);
          }),
          catchError(() => of(new UpdateMentorError(payload.mentorId)))
        )
      )
    )
  );

  public updateMentorSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateMentorSuccess>(UPDATE_MENTOR_SUCCESS),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('success', 'Mentor updated')),
        concatMap(val => [new EmailLogLoad(val.mentor.submission.id)])
      ),
    { dispatch: true }
  );

  public updateMentorError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateMentorError>(UPDATE_MENTOR_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Mentor update failed'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private mentorService: MentorService) {}
}
