import { MRStatus } from 'src/app/shared/types/mentee-relations.types';
import * as MrStatusesActions from '../actions/mr-statuses.actions';

const initialState: MRStatus[] = null;

export function reducer(state = initialState, action: MrStatusesActions.Actions): MRStatus[] {
  switch (action.type) {
    case MrStatusesActions.MRSTATUSES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
