import * as PromoCodesActions from '../actions/promo-codes.actions';
import { PromoCode } from 'src/app/shared/types/promo-code.types';

const initialState: PromoCode[] = null;

export function reducer(state = initialState, action: PromoCodesActions.Actions): PromoCode[] {
  switch (action.type) {
    case PromoCodesActions.PROMOCODES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
