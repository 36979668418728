import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  FeedbackStatusesLoad,
  FeedbackStatusesLoadError,
  FeedbackStatusesLoadSuccess,
  FEEDBACKSTATUSES_LOAD,
  FEEDBACKSTATUSES_LOAD_ERROR,
  FEEDBACKSTATUSES_LOAD_SUCCESS,
} from '../actions/feedback-status.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { FeedbackStatusService } from 'src/app/shared/services/feedback-status.service';

@Injectable()
export class FeedbackStatusEffects {
  public feedbackstatusesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FeedbackStatusesLoad>(FEEDBACKSTATUSES_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.feedbackStatusService.getAllFeedbackStatuses().pipe(
          map(feedbackstatuses => {
            return new FeedbackStatusesLoadSuccess(feedbackstatuses);
          }),
          catchError(() => of(new FeedbackStatusesLoadError()))
        )
      )
    )
  );

  public feedbackstatusesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FeedbackStatusesLoadSuccess>(FEEDBACKSTATUSES_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public feedbackstatusesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FeedbackStatusesLoadError>(FEEDBACKSTATUSES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'FeedbackStatuses not loaded'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private feedbackStatusService: FeedbackStatusService
  ) {}
}
