import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Answer } from '../types/answer.types';
import { AnswerTopic } from '../types/answer-topics.types';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AnswersService {
  constructor(private http: HttpClient) {}

  /**
   * Returns answers
   * @returns answers
   */
  public getAnswers(submissionId: string) {
    return this.http.get<Answer[]>(environment.apiUrl + '/answers/submission/' + submissionId + '/');
  }

  public getFocusedTopics() {
    return this.http.get<AnswerTopic[]>(environment.apiUrl + '/answers/focused_areas/');
  }

  public updateAnswer(answerId: string, newValue: string) {
    return this.http.patch<Answer>(environment.apiUrl + '/answers/' + answerId + '/', { value: newValue });
  }
}
