import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { Answer } from 'src/app/shared/types/answer.types';
import { Badge } from 'src/app/shared/types/badge.types';
import { City } from 'src/app/shared/types/city.types';
import { Coach } from 'src/app/shared/types/coach.types';
import { Conversation } from 'src/app/shared/types/conversation.types';
import { Country } from 'src/app/shared/types/country.types';
import { EmailLog } from 'src/app/shared/types/email-log.types';
import { Environment } from 'src/app/shared/types/environment.types';
import { FeedbackStatus } from 'src/app/shared/types/feedback-status.types';
import { Feedback } from 'src/app/shared/types/feedback.types';
import { FeedbackNeeded } from 'src/app/shared/types/feedback-needed.types';
import { Field } from 'src/app/shared/types/field.types';
import { Gender } from 'src/app/shared/types/gender.types';
import { Language } from 'src/app/shared/types/language.types';
import { Lead } from 'src/app/shared/types/lead.types';
import { MenteeRelation, MRStatus } from 'src/app/shared/types/mentee-relations.types';
import { Mentee } from 'src/app/shared/types/mentee.types';
import { Mentor } from 'src/app/shared/types/mentor.types';
import { PersonalAttribute } from 'src/app/shared/types/personal-attribute.types';
import { Programme } from 'src/app/shared/types/programme.types';
import { PromoCode } from 'src/app/shared/types/promo-code.types';
import { Skill } from 'src/app/shared/types/skill.types';
import { Status } from 'src/app/shared/types/status.types';
import { Submission } from 'src/app/shared/types/submission.types';
import { User } from 'src/app/shared/types/user.types';
import { Value } from 'src/app/shared/types/value-type.types';
import { environment } from '../../../environments/environment';
import { Settings } from '../../shared/types/settings.types';
import * as answerTopicsReducer from './answer-topics.reducer';
import * as answersReducer from './answer.reducer';
import * as badgesReducer from './badges.reducer';
import * as citiesReducer from './cities.reducer';
import * as coachReducer from './coach.reducer';
import * as coachesReducer from './coaches.reducer';
import * as conversationReducer from './conversation.reducer';
import * as countriesReducer from './countries.reducer';
import * as emailLogReducer from './email-log.reducer';
import * as environmentReducer from './environments.reducer';
import * as feedbackStatusReducer from './feedback-status.reducer';
import * as feedbacksNeededReducer from './feedbacks-needed.reducer';
import * as feedbacksReducer from './feedbacks.reducer';
import * as fieldsReducer from './fields.reducer';
import * as genderReducer from './gender.reducer';
import * as languagesReducer from './languages.reducer';
import * as leadReducer from './lead.reducer';
import * as leadsReducer from './leads.reducer';
import * as menteeRelationsReducer from './mentee-relations.reducer';
import * as menteeReducer from './mentee.reducer';
import * as menteesReducer from './mentees.reducer';
import * as mentorReducer from './mentor.reducer';
import * as mentorsReducer from './mentors.reducer';
import * as mrStatusesReducer from './mr-statuses.reducer';
import * as programmesReducer from './programmes.reducer';
import * as promocodeReducer from './promo-codes.reducer';
import * as settingsReducer from './settings.reducer';
import * as skillsReducer from './skills.reducer';
import * as statusesReducer from './statuses.reducer';
import * as leadStatusesReducer from './lead-statuses.reducer';
import * as submissionReducer from './submission.reducer';
import * as teamSizeReducer from './team-sizes.reducer';
import * as userReducer from './user.reducer';
import * as personalAttributeReducer from './personal-attributes.reducer';
import { StatusLead } from 'src/app/shared/types/status-lead.types';
import { reminderReducer, ReminderState } from './reminders.reducer';
import { AnswerTopic } from 'src/app/shared/types/answer-topics.types';

export interface AppState {
  answerTopics: AnswerTopic[];
  answers: Answer[];
  badges: Badge[];
  cities: City[];
  coach: Coach;
  coaches: Coach[];
  conversations: Conversation[];
  countries: Country[];
  decisionLeadStatuses: StatusLead[];
  emailLogs: EmailLog[];
  environments: Environment[];
  feedbacks: {
    items: Feedback[];
    error: string;
  };
  feedbacksNeeded: FeedbackNeeded[];
  feedbackStatuses: FeedbackStatus[];
  fields: Field[];
  gender: Gender[];
  languages: Language[];
  lead: Lead | string;
  leads: Lead[];
  mentee: Mentee | string;
  mentees: Mentee[];
  mentor: Mentor;
  mentors: Mentor[];
  menteeRelations: MenteeRelation[];
  mrstatuses: MRStatus[];
  personalAttributes: PersonalAttribute[];
  postLeadStatuses: StatusLead[];
  preLeadStatuses: StatusLead[];
  promoCodes: PromoCode[];
  programmes: Programme[];
  reminders: ReminderState;
  router: RouterReducerState;
  settings: Settings;
  skills: Skill[];
  statuses: Status[];
  submissions: Submission[];
  teamSizes: Value[];
  users: User[];
}

export const reducers: ActionReducerMap<AppState> = {
  answerTopics: answerTopicsReducer.reducer,
  answers: answersReducer.reducer,
  badges: badgesReducer.reducer,
  cities: citiesReducer.reducer,
  coach: coachReducer.reducer,
  coaches: coachesReducer.reducer,
  conversations: conversationReducer.reducer,
  countries: countriesReducer.reducer,
  decisionLeadStatuses: leadStatusesReducer.decisionLeadReducer,
  emailLogs: emailLogReducer.reducer,
  environments: environmentReducer.reducer,
  feedbacks: feedbacksReducer.reducer,
  feedbacksNeeded: feedbacksNeededReducer.reducer,
  feedbackStatuses: feedbackStatusReducer.reducer,
  fields: fieldsReducer.reducer,
  gender: genderReducer.reducer,
  languages: languagesReducer.reducer,
  lead: leadReducer.reducer,
  leads: leadsReducer.reducer,
  mentee: menteeReducer.reducer,
  mentees: menteesReducer.reducer,
  mentor: mentorReducer.reducer,
  mentors: mentorsReducer.reducer,
  menteeRelations: menteeRelationsReducer.reducer,
  mrstatuses: mrStatusesReducer.reducer,
  personalAttributes: personalAttributeReducer.reducer,
  postLeadStatuses: leadStatusesReducer.postLeadReducer,
  preLeadStatuses: leadStatusesReducer.preLeadReducer,
  promoCodes: promocodeReducer.reducer,
  programmes: programmesReducer.reducer,
  reminders: reminderReducer,
  router: routerReducer,
  settings: settingsReducer.reducer,
  statuses: statusesReducer.reducer,
  skills: skillsReducer.reducer,
  submissions: submissionReducer.reducer,
  teamSizes: teamSizeReducer.reducer,
  users: userReducer.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
