import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';

import { LeadService } from 'src/app/shared/services/lead.service';
import { NotificationService } from '../../shared/services/notification.service';

import { of } from 'rxjs';
import { Lead } from 'src/app/shared/types/lead.types';
import { AnswersLoad } from '../actions/answer.actions';
import { ConversationLoad } from '../actions/conversation.actions';
import { EmailLogLoad } from '../actions/email-log.actions';
import {
  LeadLoad,
  LeadLoadError,
  LeadLoadSuccess,
  LEAD_LOAD,
  LEAD_LOAD_ERROR,
  LEAD_LOAD_SUCCESS,
  UpdateLead,
  UpdateLeadError,
  UpdateLeadSuccess,
  UPDATE_LEAD,
  UPDATE_LEAD_ERROR,
  UPDATE_LEAD_SUCCESS,
} from '../actions/lead.actions';

@Injectable()
export class LeadEffects {
  public leadLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LeadLoad>(LEAD_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.leadService.getLeadData(payload.id).pipe(
          map(data => {
            const lead: Lead = data;
            return new LeadLoadSuccess(lead);
          }),
          catchError(() => of(new LeadLoadError()))
        )
      )
    )
  );

  public leadLoadSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LeadLoadSuccess>(LEAD_LOAD_SUCCESS),
      tap(() => (this.notificationService.loading = false)),
      mergeMap(payload => [
        new AnswersLoad(payload.payload.submission.id),
        new ConversationLoad(payload.payload.submission.id),
        new EmailLogLoad(payload.payload.submission.id),
      ])
    )
  );

  public leadLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LeadLoadError>(LEAD_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Lead update failed'))
      ),
    { dispatch: false }
  );

  // Update lead

  public updateLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateLead>(UPDATE_LEAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.leadService.updateLead(payload.leadId, payload.lead).pipe(
          map(data => {
            const lead: Lead = data;
            return new UpdateLeadSuccess(lead);
          }),
          catchError(error => of(new UpdateLeadError(error.error)))
        )
      )
    )
  );

  public updateLeadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateLeadSuccess>(UPDATE_LEAD_SUCCESS),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('success', 'Lead updated')),
        concatMap(val => [new EmailLogLoad(val.lead.submission.id)])
      ),
    { dispatch: false }
  );

  public updateLeadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateLeadError>(UPDATE_LEAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(err => this.notificationService.openSnackBar('error', err.error))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private leadService: LeadService) {}
}
