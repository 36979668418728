import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  AnswersLoad,
  AnswersLoadError,
  AnswersLoadSuccess,
  ANSWERS_LOAD,
  ANSWERS_LOAD_ERROR,
  ANSWERS_LOAD_SUCCESS,
  UpdateAnswer,
  UpdateAnswerSuccess,
  UpdateAnswerError,
  UPDATE_ANSWER,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_ERROR,
} from '../actions/answer.actions';
import { NotificationService } from '../../shared/services/notification.service';

import { AnswersService } from '../../shared/services/answers.service';
import { Answer } from 'src/app/shared/types/answer.types';

@Injectable()
export class AnswersEffects {
  public answersLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AnswersLoad>(ANSWERS_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.answersService.getAnswers(payload.submissionId).pipe(
          map(data => {
            const answers: Answer[] = data;
            return new AnswersLoadSuccess(answers);
          }),
          catchError(() => of(new AnswersLoadError(payload.submissionId)))
        )
      )
    )
  );

  public anwersLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AnswersLoadSuccess>(ANSWERS_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public anwersLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AnswersLoadSuccess>(ANSWERS_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Answers not loaded'))
      ),
    { dispatch: false }
  );

  // Update answer

  public updateAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAnswer>(UPDATE_ANSWER),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.answersService.updateAnswer(payload.answerId, payload.value).pipe(
          map(data => {
            const answer: Answer = data;
            return new UpdateAnswerSuccess(answer);
          }),
          catchError(() => of(new UpdateAnswerError(payload.answerId)))
        )
      )
    )
  );

  public updateAnswerSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateAnswerSuccess>(UPDATE_ANSWER_SUCCESS),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('success', 'Answer updated'))
      ),
    { dispatch: false }
  );

  public updateAnswerError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateAnswerError>(UPDATE_ANSWER_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Answer update failed'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private answersService: AnswersService) {}
}
