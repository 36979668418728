<fp-header></fp-header>

<p-sidebar [(visible)]="visibleSidebar" [baseZIndex]="10000">
  <fp-left-menu></fp-left-menu>
</p-sidebar>

<div class="main-container flex justify-content-center">
  <div class="inner-container">
    <div class="flex">
      <button
        id="panel-button"
        (click)="visibleSidebar = true"
        pButton
        pRipple
        type="button"
        icon="pi pi-bars"
        class="p-button-rounded p-button-text"
      ></button>
      <div id="left-menu" class="pr-3">
        <fp-left-menu></fp-left-menu>
      </div>
      <div class="right-container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
