import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Comment } from '../types/comment.types';
import { Conversation } from '../types/conversation.types';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  constructor(private http: HttpClient) {}

  public addComment(conversation: Partial<Conversation>, comment: Comment) {
    return this.http.post<{
      conversation: Conversation;
      slack: { slack_notification: boolean; slack_notification_success: boolean };
    }>(environment.apiUrl + '/comment/', {
      conversation,
      comment,
    });
  }
}
