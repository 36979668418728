import { Injectable } from '@angular/core';
import { Submission } from 'src/app/shared/types/submission.types';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class SubmissionService {
  constructor(private http: HttpClient) {}

  /**
   * Returns submissions
   * @returns Submission[]
   */
  public getSubmissions() {
    return this.http.get<{ submissions: Submission[] }>(environment.apiUrl + '/submission/all/');
  }
}
