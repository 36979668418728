import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  BadgesLoad,
  BadgesLoadError,
  BadgesLoadSuccess,
  BADGES_LOAD,
  BADGES_LOAD_ERROR,
  BADGES_LOAD_SUCCESS,
} from '../actions/badges.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { BadgeService } from 'src/app/shared/services/badge.service';

@Injectable()
export class BadgeEffects {
  public badgesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BadgesLoad>(BADGES_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.badgeService.getBadges().pipe(
          map(badges => {
            return new BadgesLoadSuccess(badges);
          }),
          catchError(() => of(new BadgesLoadError()))
        )
      )
    )
  );

  public badgesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BadgesLoadSuccess>(BADGES_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public badgesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BadgesLoadError>(BADGES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Badges not loaded'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private badgeService: BadgeService) {}
}
