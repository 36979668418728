import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrimeNGConfig } from 'primeng/api';
import { DL_SIGNUP_URL, FAREWELL, LOGINURL, MAINTENANCE, NOTFOUNDURL, PASSWORD_RESET_URL } from 'src/app/app.routes';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { AppState } from '../../reducers';

@Component({
  selector: 'fp-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store<AppState>,
    public authService: AuthenticationService,
    private utilsService: UtilsService
  ) {}
  currentUrl$ = this.store.select(state => state.router?.state?.url.split('?')[0]);
  loginUrl = LOGINURL;
  dlSignupUrl = DL_SIGNUP_URL;
  notFounUrl = NOTFOUNDURL;
  passwordResetUrl = PASSWORD_RESET_URL;
  farewell = FAREWELL;
  maintenanceUrl = MAINTENANCE;
  ngOnInit() {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation({
      firstDayOfWeek: 1,
    });
    this.authService.getMe().then(user => {
      if (user) {
        this.utilsService.loadData2Store();
      }
    });
  }
}
