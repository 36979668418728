import { Action } from '@ngrx/store';
import { Submission } from 'src/app/shared/types/submission.types';

export const SUBMISSIONS_LOAD = '[SUBMISSION] Load submissions';
export const SUBMISSIONS_LOAD_SUCCESS = '[SUBMISSION] Load submissions successful';

export class SubmissionsLoad implements Action {
  readonly type = SUBMISSIONS_LOAD;
  constructor() {}
}

export class SubmissionsLoadSuccess implements Action {
  readonly type = SUBMISSIONS_LOAD_SUCCESS;
  constructor(public payload: Submission[]) {}
}

export type Actions = SubmissionsLoad | SubmissionsLoadSuccess;
