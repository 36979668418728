import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, map, catchError, tap, switchMap, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ReminderService } from 'src/app/shared/services/reminders.services';
import * as ReminderActions from '../actions/reminders.actions';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable()
export class ReminderEffects {
  constructor(private actions$: Actions, private notificationService: NotificationService, private reminderService: ReminderService) {}

  loadReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReminderActions.RemindersLoad),
      mergeMap(() =>
        this.reminderService.getUserReminders().pipe(
          map(reminders => ReminderActions.RemindersLoadSuccess({ reminders: reminders })),
          catchError(() => of(ReminderActions.RemindersLoadError()))
        )
      )
    )
  );

  loadRemindersError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReminderActions.RemindersLoadError),
        tap(() => this.notificationService.openSnackBar('error', 'Loading reminders data failed'))
      ),
    { dispatch: false }
  );

  loadRemindersSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReminderActions.RemindersSubmissionLoad),
      mergeMap(action =>
        this.reminderService.getRemindersForSubmission(action.submissionId).pipe(
          map(reminders => ReminderActions.RemindersSubmissionLoadSuccess({ reminders: reminders })),
          catchError(() => of(ReminderActions.RemindersSubmissionLoadError()))
        )
      )
    )
  );

  public loadRemindersSubmissionError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReminderActions.RemindersSubmissionLoadError),
        tap(() => this.notificationService.openSnackBar('error', 'Loading reminders data failed'))
      ),
    { dispatch: false }
  );

  createReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReminderActions.ReminderCreate),
      concatMap(({ reminder }) =>
        this.reminderService.createReminder(reminder).pipe(
          map(createdReminder => ReminderActions.ReminderCreateSuccess({ reminder: createdReminder })),
          catchError(error => of(ReminderActions.ReminderCreateError({ error })))
        )
      )
    )
  );

  updateReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReminderActions.ReminderUpdate),
      concatMap(action =>
        this.reminderService.updateReminder(action.reminderId, action.reminder).pipe(
          map(updatedReminder => ReminderActions.ReminderUpdateSuccess({ reminder: updatedReminder })),
          catchError(() => of(ReminderActions.ReminderUpdateError()))
        )
      )
    )
  );

  updateReminderSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReminderActions.ReminderUpdateSuccess),
      map(() => ReminderActions.RemindersLoad())
    )
  );

  updateReminderArray$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReminderActions.ReminderArrayUpdate),
      concatMap(action =>
        this.reminderService.updateReminderArray(action.reminders).pipe(
          map(updatedReminders => ReminderActions.ReminderArrayUpdateSuccess({ reminders: updatedReminders })),
          catchError(() => of(ReminderActions.ReminderArrayUpdateError()))
        )
      )
    )
  );

  updateReminderArraySucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReminderActions.ReminderArrayUpdateSuccess),
      map(() => ReminderActions.RemindersLoad())
    )
  );
}
