import { Lead } from 'src/app/shared/types/lead.types';
import * as LeadsActions from '../actions/leads.actions';

const initialState: Lead[] = null;

export function reducer(state = initialState, action: LeadsActions.Actions): Lead[] {
  switch (action.type) {
    case LeadsActions.LEADS_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
