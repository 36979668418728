import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { SubmissionsLoad, SubmissionsLoadSuccess, SUBMISSIONS_LOAD, SUBMISSIONS_LOAD_SUCCESS } from '../actions/submission.actions';
import { NotificationService } from '../../shared/services/notification.service';

import { SubmissionService } from '../../dashboard/services/submission.service';
import { EMPTY } from 'rxjs';
import { Submission } from 'src/app/shared/types/submission.types';

@Injectable()
export class SubmissionsEffects {
  public submissionsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmissionsLoad>(SUBMISSIONS_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.submissionService.getSubmissions().pipe(
          map(data => {
            const submissions: Submission[] = data.submissions;
            return new SubmissionsLoadSuccess(submissions);
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  public submissionsLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SubmissionsLoadSuccess>(SUBMISSIONS_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
        // tap(() => this.notificationService.openSnackBar('success', 'Effect!', 'Submissions loaded'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private submissionService: SubmissionService) {}
}
