import { Mentee } from 'src/app/shared/types/mentee.types';
import * as MenteeActions from '../actions/mentee.actions';

type ErrorType = string;
const initialState: Mentee | ErrorType = null;

export function reducer(state = initialState, action: MenteeActions.Actions): Mentee | ErrorType {
  switch (action.type) {
    case MenteeActions.MENTEE_SET_INITIAL:
      return initialState;
    case MenteeActions.MENTEE_LOAD_SUCCESS:
      return action.payload;
    case MenteeActions.UPDATE_MENTEE_SUCCESS:
      return action.mentee;
    default:
      return state;
  }
}
