import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../../shared/services/notification.service';
import { MenteesLoadError, MenteesLoadSuccess, MENTEES_LOAD_ERROR, MENTEES_LOAD_SUCCESS } from '../actions/mentees.actions';

@Injectable()
export class MenteesEffects {
  public menteesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MenteesLoadSuccess>(MENTEES_LOAD_SUCCESS),
        tap(payload => {
          this.notificationService.loading = false;
        })
      ),
    { dispatch: false }
  );

  public menteesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MenteesLoadError>(MENTEES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Mentees update failed'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService) {}
}
