import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { UpdateUser } from 'src/app/core/actions/settings.actions';
import { AppState } from 'src/app/core/reducers';
import { AuthenticationService } from './authentication.service';
import { OriginalUrlService } from './original-url.service';

@Injectable()
export class AuthGuardService {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private store: Store<AppState>,
    private originalUrlService: OriginalUrlService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = await this.authService.getMe();
    if (user && user.role == 'AD') {
      this.store.dispatch(new UpdateUser(user));
      return true;
    } else {
      // Store the original URL in the service
      this.originalUrlService.setOriginalUrl(state.url);
      // Redirect to the login page
      this.router.navigate(['/auth']);
      return false;
    }
  }
}
