import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { NotificationService } from '../../shared/services/notification.service';

import { of } from 'rxjs';
import { MenteeRelationService } from 'src/app/shared/services/mentee-relation.service';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import { CoachLoad } from '../actions/coach.actions';
import {
  CreateMenteeRelation,
  CREATE_MENTEERELATION,
  MenteeRelationLoad,
  MenteeRelationLoadError,
  MenteeRelationLoadSuccess,
  MENTEERELATION_LOAD,
  MENTEERELATION_LOAD_ERROR,
  MENTEERELATION_LOAD_SUCCESS,
  UpdateMenteeRelation,
  UpdateMenteeRelationError,
  UpdateMenteeRelationSuccess,
  UPDATE_MENTEERELATION,
  UPDATE_MENTEERELATION_ERROR,
  UPDATE_MENTEERELATION_SUCCESS,
} from '../actions/mentee-relations.actions';
import { MenteeLoad } from '../actions/mentee.actions';
import { MentorLoad } from '../actions/mentor.actions';

@Injectable()
export class MenteeRelationsEffects {
  public menteeRelationLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MenteeRelationLoad>(MENTEERELATION_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.menteerelationService.getMenteeRelations(payload.id, payload.mmType).pipe(
          map(data => {
            const menteeRelations: MenteeRelation[] = data;
            return new MenteeRelationLoadSuccess(menteeRelations);
          }),
          catchError(() => of(new MenteeRelationLoadError()))
        )
      )
    )
  );

  public menteerelationLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MenteeRelationLoadSuccess>(MENTEERELATION_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public menteerelationLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MenteeRelationLoadError>(MENTEERELATION_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Relation load failed'))
      ),
    { dispatch: false }
  );

  public updateMenteerelation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateMenteeRelation>(UPDATE_MENTEERELATION),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.menteerelationService.updateMenteeRelation(payload.menteeRelation).pipe(
          map(data => {
            const menteeRelations: MenteeRelation = data;
            return new UpdateMenteeRelationSuccess(menteeRelations, payload.originator);
          }),
          catchError(() => of(new UpdateMenteeRelationError()))
        )
      )
    )
  );

  public updateMenteerelationSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateMenteeRelationSuccess>(UPDATE_MENTEERELATION_SUCCESS),
      tap(() => (this.notificationService.loading = false)),
      tap(() => this.notificationService.openSnackBar('success', 'Relation updated')),
      mergeMap(payload => {
        if (payload.originator.type === 'mentee') {
          return [new MenteeRelationLoad(payload.originator.id, payload.originator.type), new MenteeLoad(payload.originator.id)];
        } else if (payload.originator.type === 'mentor') {
          return [new MenteeRelationLoad(payload.originator.id, payload.originator.type), new MentorLoad(payload.originator.id)];
        } else if (payload.originator.type === 'coach') {
          return [new MenteeRelationLoad(payload.originator.id, payload.originator.type), new CoachLoad(payload.originator.id)];
        } else {
          throw 'Invalid originator type';
        }
      })
    )
  );

  public updateMenteerelationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateMenteeRelationError>(UPDATE_MENTEERELATION_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Relation update failed'))
      ),
    { dispatch: false }
  );

  public createMenteerelation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateMenteeRelation>(CREATE_MENTEERELATION),
      tap(() => (this.notificationService.loading = false)),
      tap(() => this.notificationService.openSnackBar('success', 'Relation created')),
      mergeMap(payload => {
        if (payload.originator.type === 'mentee') {
          return of(new MenteeLoad(payload.originator.id));
        } else if (payload.originator.type === 'mentor') {
          return of(new MentorLoad(payload.originator.id));
        } else if (payload.originator.type === 'coach') {
          return of(new CoachLoad(payload.originator.id));
        } else {
          throw 'Invalid originator type';
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private menteerelationService: MenteeRelationService
  ) {}
}
