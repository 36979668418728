import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './core/containers/app/app.component';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';

import { AppRoutes, routes } from './app.routes';
import { CoreModule } from './core/core.module';
import { reducers, metaReducers } from './core/reducers';
import { environment } from '../environments/environment';

import { MessageService, ConfirmationService, FilterService } from 'primeng/api';
import { RouteService } from './shared/services/route.service';
import { TokenInterceptor } from './shared/services/token.interceptor';
import { AuthenticationService } from './shared/services/authentication.service';
import { GeneralInterceptor } from './shared/services/general.interceptor';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [],
  imports: [
    AppRoutes,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: false }),
    StoreModule.forRoot(reducers, {
      // this should be in particular module, maybe Core?
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
  ],
  providers: [
    AuthenticationService,
    MessageService,
    ConfirmationService,
    FilterService,
    RouteService,
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: GeneralInterceptor,
        multi: true,
      },
    ],
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
