import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Reminder } from '../types/reminder.types';

@Injectable({
  providedIn: 'root',
})
export class ReminderService {
  constructor(private http: HttpClient) {}

  public getUserReminders() {
    return this.http.get<Reminder[]>(environment.apiUrl + '/reminders/');
  }

  public getRemindersForSubmission(submissionId: string) {
    return this.http.get<Reminder[]>(environment.apiUrl + '/reminders/submission/' + submissionId + '/');
  }

  public createReminder(reminder: Partial<Reminder>) {
    return this.http.post<Reminder>(environment.apiUrl + '/reminders/', reminder);
  }

  public updateReminder(reminderID: string, updatedReminder: Partial<Reminder>) {
    return this.http.patch<Reminder>(environment.apiUrl + '/reminders/' + reminderID + '/', updatedReminder);
  }

  public updateReminderArray(reminderArray: Reminder[]) {
    return this.http.post<Reminder[]>(environment.apiUrl + '/reminders/array/', reminderArray);
  }
}
