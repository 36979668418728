import * as ProgrammesActions from '../actions/programmes.actions';
import { Programme } from 'src/app/shared/types/programme.types';

const initialState: Programme[] = null;

export function reducer(state = initialState, action: ProgrammesActions.Actions): Programme[] {
  switch (action.type) {
    case ProgrammesActions.PROGRAMMES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
