import { EmailLog } from 'src/app/shared/types/email-log.types';
import * as EmailLogActions from '../actions/email-log.actions';
import { cloneDeep } from 'lodash';

const initialState: EmailLog[] = null;

export function reducer(state = initialState, action: EmailLogActions.Actions): EmailLog[] {
  switch (action.type) {
    case EmailLogActions.EMAILLOG_SET_INITIAL:
      return initialState;
    case EmailLogActions.EMAILLOG_LOAD_SUCCESS:
      return action.payload;
    case EmailLogActions.UPDATE_EMAILLOG_SUCCESS: {
      const newState = cloneDeep(state).map(item => {
        if (item.id !== action.payload.id) {
          return item;
        } else {
          return { ...item, ...action.payload };
        }
      });
      return newState;
    }
    case EmailLogActions.EMAILLOG_SEND_SUCCESS: {
      const newState = cloneDeep(state).map(item => {
        if (item.id !== action.emailLog.id) {
          return item;
        } else {
          return { ...item, ...action.emailLog };
        }
      });
      return newState;
    }
    default:
      return state;
  }
}
