import * as FeedbackStatusesActions from '../actions/feedback-status.actions';
import { FeedbackStatus } from 'src/app/shared/types/feedback-status.types';

const initialState: FeedbackStatus[] = null;

export function reducer(state = initialState, action: FeedbackStatusesActions.Actions): FeedbackStatus[] {
  switch (action.type) {
    case FeedbackStatusesActions.FEEDBACKSTATUSES_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
