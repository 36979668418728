import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { FeedbackService } from 'src/app/shared/services/feedback.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { NotificationService } from '../../shared/services/notification.service';
import {
  FeedbackLoad,
  FeedbackLoadError,
  FeedbackLoadSuccess,
  FeedbacksLoadBySubmission,
  FeedbacksLoadBySubmissionError,
  FeedbacksLoadBySubmissionSuccess,
  FeedbacksLoadError,
  FeedbacksLoadSuccess,
  FEEDBACKS_LOAD_BY_SUBMISSION,
  FEEDBACKS_LOAD_BY_SUBMISSION_ERROR,
  FEEDBACKS_LOAD_BY_SUBMISSION_SUCCESS,
  FEEDBACKS_LOAD_ERROR,
  FEEDBACKS_LOAD_SUCCESS,
  FeedbackUpdate,
  FeedbackUpdateError,
  FeedbackUpdateSuccess,
  FEEDBACK_LOAD,
  FEEDBACK_LOAD_ERROR,
  FEEDBACK_LOAD_SUCCESS,
  UPDATE_FEEDBACK,
  UPDATE_FEEDBACK_ERROR,
  UPDATE_FEEDBACK_SUCCESS,
} from '../actions/feedbacks.actions';
import { AppState } from '../reducers';

@Injectable()
export class FeedbacksEffects {
  public feedbacksLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FeedbacksLoadSuccess>(FEEDBACKS_LOAD_SUCCESS),
        tap(payload => {
          this.notificationService.loading = false;
        })
      ),
    { dispatch: false }
  );

  public feedbacksLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FeedbacksLoadError>(FEEDBACKS_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Feedbacks load failed'))
      ),
    { dispatch: false }
  );

  public feedbacksLoadBySubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FeedbacksLoadBySubmission>(FEEDBACKS_LOAD_BY_SUBMISSION),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.feedbackService.getAllFeedbacksBySubmissionId(payload.modelType, payload.id).pipe(
          map(feedbacks => {
            return new FeedbacksLoadBySubmissionSuccess(feedbacks);
          }),
          catchError(() => of(new FeedbacksLoadBySubmissionError()))
        )
      )
    )
  );

  public feedbacksLoadBySubmissionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FeedbacksLoadBySubmissionSuccess>(FEEDBACKS_LOAD_BY_SUBMISSION_SUCCESS),
        tap(payload => {
          this.notificationService.loading = false;
        })
      ),
    { dispatch: false }
  );

  public feedbacksLoadBySubmissionError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FeedbacksLoadBySubmissionError>(FEEDBACKS_LOAD_BY_SUBMISSION_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Feedbacks load failed'))
      ),
    { dispatch: false }
  );

  public feedbackLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FeedbackLoad>(FEEDBACK_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.feedbackService.getFeedbackDetail(payload.id).pipe(
          map(feedback => {
            return new FeedbackLoadSuccess(feedback);
          }),
          catchError(() => of(new FeedbackLoadError()))
        )
      )
    )
  );

  public feedbackLoadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FeedbackLoadSuccess>(FEEDBACK_LOAD_SUCCESS),
        tap(payload => {
          this.notificationService.loading = false;
        })
      ),
    { dispatch: false }
  );

  public feedbackLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FeedbackLoadError>(FEEDBACK_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Feedback load failed'))
      ),
    { dispatch: false }
  );

  public updateFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FeedbackUpdate>(UPDATE_FEEDBACK),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.feedbackService.updateFeedback(payload.feedback).pipe(
          map(feedback => {
            return new FeedbackUpdateSuccess(feedback);
          }),
          catchError(error => of(new FeedbackUpdateError(error.error)))
        )
      )
    )
  );

  public updateFeedbackSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FeedbackLoadSuccess>(UPDATE_FEEDBACK_SUCCESS),
        withLatestFrom(this.store$.select(state => state.feedbacks)),
        tap(([payload, feedbacks]) => {
          this.notificationService.loading = false;
        }),
        tap(() => this.notificationService.openSnackBar('success', 'Feedback updated'))
      ),
    { dispatch: false }
  );

  public updateFeedbackError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FeedbackUpdateError>(UPDATE_FEEDBACK_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(err => this.notificationService.openSnackBar('error', err.error))
      ),
    { dispatch: false }
  );

  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private notificationService: NotificationService,
    private feedbackService: FeedbackService
  ) {}
}
