import * as LeadStatusesActions from '../actions/lead-statuses.actions';
import { StatusLead } from 'src/app/shared/types/status-lead.types';
import {
  DECISION_LEAD_STATUSES_LOAD_SUCCESS,
  POST_LEAD_STATUSES_LOAD_SUCCESS,
  PRE_LEAD_STATUSES_LOAD_SUCCESS,
} from '../actions/lead-statuses.actions';

const initialState: StatusLead[] = null;

export function preLeadReducer(state = initialState, action: LeadStatusesActions.PreActions): StatusLead[] {
  switch (action.type) {
    case PRE_LEAD_STATUSES_LOAD_SUCCESS:
      return action.statuses;
    default:
      return state;
  }
}

export function postLeadReducer(state = initialState, action: LeadStatusesActions.PostActions): StatusLead[] {
  switch (action.type) {
    case POST_LEAD_STATUSES_LOAD_SUCCESS:
      return action.statuses;
    default:
      return state;
  }
}

export function decisionLeadReducer(state = initialState, action: LeadStatusesActions.DecisionActions): StatusLead[] {
  switch (action.type) {
    case DECISION_LEAD_STATUSES_LOAD_SUCCESS:
      return action.statuses;
    default:
      return state;
  }
}
