import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { NotificationService } from '../../shared/services/notification.service';

import { of } from 'rxjs';
import {
  ConversationLoad,
  ConversationLoadError,
  ConversationLoadSuccess,
  CONVERSATION_LOAD,
  CONVERSATION_LOAD_ERROR,
  CONVERSATION_LOAD_SUCCESS,
  ADD_COMMENT_TO_CONVERSATION,
  ADD_COMMENT_TO_CONVERSATION_ERROR,
  ADD_COMMENT_TO_CONVERSATION_SUCCESS,
  AddCommentToConversation,
  AddCommentToConversationSuccess,
  AddCommentToConversationError,
  ResolveConversation,
  ResolveConversationSuccess,
  ResolveConversationError,
  RESOLVE_CONVERSATION,
  RESOLVE_CONVERSATION_SUCCESS,
  RESOLVE_CONVERSATION_ERROR,
  RemoveUserFromConversation,
  REMOVE_USER_FROM_CONVERSATION,
  RemoveUserFromConversationError,
  RemoveUserFromConversationSuccess,
  REMOVE_USER_FROM_CONVERSATION_SUCCESS,
  REMOVE_USER_FROM_CONVERSATION_ERROR,
  USER_CONVERSATION_LOAD_ERROR,
  UserConversationLoadError,
  USER_CONVERSATION_LOAD,
  UserConversationLoad,
} from '../actions/conversation.actions';
import { Conversation } from 'src/app/shared/types/conversation.types';
import { ConversationService } from 'src/app/shared/services/conversation.service';
import { CommentService } from 'src/app/shared/services/comment.service';
import { UPDATE_CONVERSATIONS, UpdateConversations } from '../actions/settings.actions';

@Injectable()
export class ConversationEffects {
  public conversationLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConversationLoad>(CONVERSATION_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.conversationService.getConversationsBySubmission(payload.id).pipe(
          map(data => {
            const conversation: Conversation[] = data;
            return new ConversationLoadSuccess(conversation);
          }),
          catchError(err => {
            console.log(err);
            return of(new ConversationLoadError());
          })
        )
      )
    )
  );

  public conversationLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConversationLoadSuccess>(CONVERSATION_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public conversationLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConversationLoadError>(CONVERSATION_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Conversation find failed'))
      ),
    { dispatch: false }
  );

  // Conversations of authenticated user
  public userConversationLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserConversationLoad>(USER_CONVERSATION_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.conversationService.getUserConversations().pipe(
          map(data => {
            const conversation: Conversation[] = data;
            return new UpdateConversations(conversation);
          }),
          catchError(err => {
            return of(new UserConversationLoadError());
          })
        )
      )
    )
  );

  public userConversationLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateConversations>(UPDATE_CONVERSATIONS), // calling action fro settins.actions - need to be differ user & submission conversations
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public userConversationLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserConversationLoadError>(USER_CONVERSATION_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'User Conversation find failed'))
      ),
    { dispatch: false }
  );

  // Update conversation
  public addCommentToConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddCommentToConversation>(ADD_COMMENT_TO_CONVERSATION),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.commentService.addComment(payload.conversation, payload.comment).pipe(
          map(data => {
            const conversation: Conversation = data.conversation;
            const slackSuccess = data.slack.slack_notification ? data.slack.slack_notification_success : null;
            return new AddCommentToConversationSuccess(conversation, slackSuccess);
          }),
          catchError(() => of(new AddCommentToConversationError(payload.conversation.id)))
        )
      )
    )
  );

  public addCommentToConversationSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddCommentToConversationSuccess>(ADD_COMMENT_TO_CONVERSATION_SUCCESS),
      tap(() => (this.notificationService.loading = false)),
      tap(data => {
        if (data.slackSuccess === false) {
          this.notificationService.openSnackBar('warn', 'Slack notification not sent!', true);
        }
      }),
      map(() => new UserConversationLoad())
    )
  );

  public addCommentToConversationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AddCommentToConversationError>(ADD_COMMENT_TO_CONVERSATION_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Conversation update failed'))
      ),
    { dispatch: false }
  );

  // Resolve conversation
  public resolveConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResolveConversation>(RESOLVE_CONVERSATION),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.conversationService.resolveConversations(payload.conversation.id).pipe(
          map(data => {
            const conversation: Conversation = data;
            return new ResolveConversationSuccess(conversation);
          }),
          catchError(() => of(new ResolveConversationError()))
        )
      )
    )
  );

  public resolveConversationSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResolveConversationSuccess>(RESOLVE_CONVERSATION_SUCCESS),
      tap(() => (this.notificationService.loading = false)),
      tap(() => this.notificationService.openSnackBar('success', 'Issue resolved')),
      map(() => new UserConversationLoad())
    )
  );

  public resolveConversationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ResolveConversationError>(RESOLVE_CONVERSATION_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Conversation resolving failed'))
      ),
    { dispatch: false }
  );

  // Remove user from conversation
  public removeUserFromConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveUserFromConversation>(REMOVE_USER_FROM_CONVERSATION),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(payload =>
        this.conversationService.removeUserFromConversation(payload.conversation.id, payload.userId).pipe(
          map(data => {
            const conversation: Conversation = data;
            return new RemoveUserFromConversationSuccess(conversation);
          }),
          catchError(() => of(new RemoveUserFromConversationError()))
        )
      )
    )
  );

  public removeUserFromConversationSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RemoveUserFromConversationSuccess>(REMOVE_USER_FROM_CONVERSATION_SUCCESS),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('success', 'User removed resolved'))
      ),
    { dispatch: false }
  );

  public removeUserFromConversationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RemoveUserFromConversationError>(REMOVE_USER_FROM_CONVERSATION_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'User removal resolving failed'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private conversationService: ConversationService,
    private commentService: CommentService
  ) {}
}
