import { createReducer, on } from '@ngrx/store';
import { Reminder } from 'src/app/shared/types/reminder.types';
import * as ReminderActions from '../actions/reminders.actions';

export interface ReminderState {
  reminders: Reminder[];
  loading: boolean;
  error: string | null;
}

const initialState: ReminderState = {
  reminders: [],
  loading: false,
  error: null,
};

export const reminderReducer = createReducer(
  initialState,
  on(ReminderActions.ReminderSetInitial, () => ({ ...initialState })),
  on(ReminderActions.RemindersLoadSuccess, (state, { reminders }) => ({
    ...state,
    reminders: reminders,
    loading: false,
    error: null,
  })),
  on(ReminderActions.RemindersLoadError, state => ({
    ...state,
    loading: false,
    error: 'Error loading reminders for user',
  })),
  on(ReminderActions.ReminderUpdate, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ReminderActions.ReminderUpdateSuccess, (state, { reminder }) => ({
    ...state,
    loading: false,
    error: null,
    reminders: state.reminders.map(r => (r.uuid === reminder.uuid ? reminder : r)),
  })),
  on(ReminderActions.ReminderUpdateError, state => ({
    ...state,
    loading: false,
    error: 'Error updating reminder',
  })),
  on(ReminderActions.ReminderCreateSuccess, (state, { reminder }) => ({
    ...state,
    reminders: [...state.reminders, reminder],
    error: null,
  })),
  on(ReminderActions.ReminderCreateError, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(ReminderActions.RemindersSubmissionLoadSuccess, (state, { reminders }) => ({
    ...state,
    reminders: reminders,
    loading: false,
    error: null,
  })),
  on(ReminderActions.RemindersSubmissionLoadError, state => ({
    ...state,
    loading: false,
    error: 'Error loading reminders',
  })),

  on(ReminderActions.ReminderArrayUpdateSuccess, (state, { reminders }) => ({
    ...state,
    reminders: reminders,

    loading: false,
    error: null,
  })),
  on(ReminderActions.ReminderArrayUpdateError, state => ({
    ...state,
    loading: false,
    error: 'Error updating reminder ARRAY',
  }))
);
