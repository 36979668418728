import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { CanDeactivateGuard } from './shared/services/can-deactivate-guard.service';

export const LOGINURL = 'auth';
export const NOTFOUNDURL = 'not-found';
export const FAREWELL = 'farewell';
export const MAINTENANCE = 'maintenance';
export const DL_SIGNUP_URL = 'auth/dlsignup';
export const PASSWORD_RESET_URL = 'auth/reset/confirm';

export const routes: Routes = [
  { path: '', redirectTo: '/auth', pathMatch: 'full' },
  {
    path: LOGINURL,
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: NOTFOUNDURL,
    loadChildren: () => import('./errorpages/not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: FAREWELL,
    loadChildren: () => import('./errorpages/farewell/farewell.module').then(m => m.FarewellModule),
  },
  {
    path: MAINTENANCE,
    loadChildren: () => import('./errorpages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'detail',
    loadChildren: () => import('./detail/detail.module').then(m => m.DetailModule),
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
      // { enableTracing: !environment.production } // <-- debugging purposes only
    ),
  ],
  providers: [AuthGuardService, CanDeactivateGuard],
})
export class AppRoutes {}
