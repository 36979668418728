import { cloneDeep } from 'lodash';
import { Feedback } from 'src/app/shared/types/feedback.types';
import { AppState } from '.';
import * as FeedbacksActions from '../actions/feedbacks.actions';

const initialState: AppState['feedbacks'] = { items: [], error: '' };

export function reducer(state = initialState, action: FeedbacksActions.Actions): AppState['feedbacks'] {
  let allFeedbacks: Feedback[];
  let ix: number;
  allFeedbacks = cloneDeep(state.items) ?? [];
  switch (action.type) {
    case FeedbacksActions.FEEDBACKS_SET_INITIAL:
      return initialState;
    case FeedbacksActions.FEEDBACKS_LOAD_SUCCESS:
      const payloadCopy = cloneDeep(action.payload);
      // In case of loading feedback explicitly in url
      ix = -1;
      if (allFeedbacks.length == 1) {
        ix = action.payload.findIndex(item => item.id === allFeedbacks[0].id);
      }
      if (ix === -1) {
        return { items: payloadCopy, error: '' };
      } else {
        payloadCopy[ix].answers = allFeedbacks[0].answers;
        return { items: payloadCopy, error: '' };
      }
    case FeedbacksActions.FEEDBACKS_LOAD_BY_SUBMISSION_SUCCESS:
      action.feedbacks.forEach(feedback => {
        ix = allFeedbacks.findIndex(item => item.id === feedback.id);
        if (ix === -1) {
          allFeedbacks = [...allFeedbacks, feedback];
        } else {
          allFeedbacks[ix] = { ...allFeedbacks[ix], ...feedback };
        }
      });
      return { items: allFeedbacks, error: '' };
    case FeedbacksActions.FEEDBACK_LOAD_SUCCESS:
      ix = allFeedbacks.findIndex(item => item.id === action.payload.id);
      if (ix === -1) {
        allFeedbacks = [...allFeedbacks, action.payload];
      } else {
        allFeedbacks[ix] = { ...allFeedbacks[ix], ...action.payload };
      }
      return { items: allFeedbacks, error: '' };
    case FeedbacksActions.UPDATE_FEEDBACK_SUCCESS:
      ix = allFeedbacks.findIndex(item => item.id === action.payload.id);
      if (ix === -1) {
        allFeedbacks = [...allFeedbacks, action.payload];
      } else {
        allFeedbacks[ix] = action.payload;
      }
      return { items: allFeedbacks, error: '' };
    case FeedbacksActions.UPDATE_FEEDBACK_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
