import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { UsersLoad, UsersLoadError, UsersLoadSuccess, USERS_LOAD, USERS_LOAD_ERROR, USERS_LOAD_SUCCESS } from '../actions/user.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable()
export class UserEffects {
  public usersLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UsersLoad>(USERS_LOAD),
      tap(() => (this.notificationService.loading = true)),
      mergeMap(() =>
        this.userService.getAllUsers().pipe(
          map(users => {
            return new UsersLoadSuccess(users);
          }),
          catchError(() => of(new UsersLoadError()))
        )
      )
    )
  );

  public usersLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UsersLoadSuccess>(USERS_LOAD_SUCCESS),
        tap(() => (this.notificationService.loading = false))
      ),
    { dispatch: false }
  );

  public usersLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UsersLoadError>(USERS_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Users not loaded'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private userService: UserService) {}
}
