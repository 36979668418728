import { cloneDeep } from 'lodash';
import { Answer } from 'src/app/shared/types/answer.types';
import * as AnswersActions from '../actions/answer.actions';

const initialState: Answer[] = null;

export function reducer(state = initialState, action: AnswersActions.Actions): Answer[] {
  let allAnswers: Answer[];
  let ix: number;
  allAnswers = cloneDeep(state) ?? [];
  switch (action.type) {
    case AnswersActions.ANSWERS_SET_INITIAL:
      return initialState;
    case AnswersActions.ANSWERS_LOAD_SUCCESS:
      return action.payload;
    case AnswersActions.UPDATE_ANSWER_SUCCESS:
      ix = allAnswers.findIndex(item => item.id === action.payload.id);
      allAnswers[ix] = { ...allAnswers[ix], ...action.payload };
      return allAnswers;
    default:
      return state;
  }
}
