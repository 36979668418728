import * as SubmissionActions from '../actions/submission.actions';
import { Submission } from '../../shared/types/submission.types';

const initialState: Submission[] = null;

export function reducer(state = initialState, action: SubmissionActions.Actions): Submission[] {
  switch (action.type) {
    case SubmissionActions.SUBMISSIONS_LOAD:
      return state;
    case SubmissionActions.SUBMISSIONS_LOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
