import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { CoachService } from 'src/app/shared/services/coach.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { NotificationService } from '../../shared/services/notification.service';
import { CoachesLoadError, CoachesLoadSuccess, COACHES_LOAD_ERROR, COACHES_LOAD_SUCCESS } from '../actions/coaches.actions';
import { AppState } from '../reducers';

@Injectable()
export class CoachesEffects {
  public coachesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CoachesLoadSuccess>(COACHES_LOAD_SUCCESS),
        tap(payload => {
          this.notificationService.loading = false;
        })
      ),
    { dispatch: false }
  );

  public coachesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CoachesLoadError>(COACHES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Coaches find failed'))
      ),
    { dispatch: false }
  );

  constructor(
    private store$: Store<AppState>,
    private utilService: UtilsService,
    private actions$: Actions,
    private notificationService: NotificationService,
    private coachService: CoachService
  ) {}
}
